function importAll(r) {
    r.keys().forEach(r);
}

// Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'

// Icons
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-icons/font/bootstrap-icons.min.css'

// Other
importAll(require.context('aos/dist/', true, /\.(.css|.js)$/));
import 'glightbox/dist/css/glightbox.min.css';
import 'glightbox/dist/js/glightbox.min.js';
importAll(require.context('isotope-layout/dist', true, /\.min.js$/));
import 'swiper/swiper-bundle.min.css';
// not exported in node_modules
import './assets/vendor/swiper/swiper-bundle.min.js';

// Custom
import './assets/css/main.css'
import './assets/js/main.js'

// Assets
// Images
importAll(require.context('./assets/img/', true, /\.(png|avif)$/));
